import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"{{if this.isStatusBlocking \"certification-list-page__cell--important\"}}\">\n  {{@record.statusLabel}}\n</span>", {"contents":"<span class=\"{{if this.isStatusBlocking \"certification-list-page__cell--important\"}}\">\n  {{@record.statusLabel}}\n</span>","moduleName":"pix-admin/components/certifications/status.hbs","parseOptions":{"srcName":"pix-admin/components/certifications/status.hbs"}});
import Component from '@glimmer/component';
import includes from 'lodash/includes';
import { STARTED, ERROR } from 'pix-admin/models/certification';
import { ENDED_BY_SUPERVISOR } from 'pix-admin/models/jury-certification-summary';

export default class CertificationStatusComponent extends Component {
  get isStatusBlocking() {
    const blokingStatuses = [STARTED, ERROR, ENDED_BY_SUPERVISOR];
    return includes(blokingStatuses, this.args.record.status) || this.args.record.isFlaggedAborted;
  }
}
