import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @display}}\n  <ModalDialog\n    @translucentOverlay={{true}}\n    @targetAttachment=\"none\"\n    @wrapperClass=\"modal-wrapper\"\n    @overlayClass=\"modal-overlay\"\n    @containerClass=\"{{this.containerClass}}\"\n    @onClose={{@close}}\n  >\n    <div class=\"modal__container\" role=\"dialog\" aria-labelledby={{this.labelId}} aria-modal=\"true\" ...attributes>\n      <div class=\"modal-header\">\n        <h2 class=\"modal-header__title\" id={{this.labelId}}>\n          {{@title}}\n        </h2>\n        <PixIconButton\n          @icon=\"times\"\n          aria-label=\"Fermer\"\n          @triggerAction={{@close}}\n          @withBackground={{false}}\n          class=\"modal-header__icon\"\n        />\n      </div>\n      <div>\n        {{yield}}\n      </div>\n    </div>\n  </ModalDialog>\n{{/if}}", {"contents":"{{#if @display}}\n  <ModalDialog\n    @translucentOverlay={{true}}\n    @targetAttachment=\"none\"\n    @wrapperClass=\"modal-wrapper\"\n    @overlayClass=\"modal-overlay\"\n    @containerClass=\"{{this.containerClass}}\"\n    @onClose={{@close}}\n  >\n    <div class=\"modal__container\" role=\"dialog\" aria-labelledby={{this.labelId}} aria-modal=\"true\" ...attributes>\n      <div class=\"modal-header\">\n        <h2 class=\"modal-header__title\" id={{this.labelId}}>\n          {{@title}}\n        </h2>\n        <PixIconButton\n          @icon=\"times\"\n          aria-label=\"Fermer\"\n          @triggerAction={{@close}}\n          @withBackground={{false}}\n          class=\"modal-header__icon\"\n        />\n      </div>\n      <div>\n        {{yield}}\n      </div>\n    </div>\n  </ModalDialog>\n{{/if}}","moduleName":"pix-admin/components/modal/dialog.hbs","parseOptions":{"srcName":"pix-admin/components/modal/dialog.hbs"}});
import snakeCase from 'lodash/snakeCase';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class Dialog extends Component {
  constructor() {
    super(...arguments);
    document.addEventListener('keyup', this.closeOnEscape);
  }

  get containerClass() {
    return `modal-dialog ${this.args.additionalContainerClass}`;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    document.removeEventListener('keyup', this.closeOnEscape);
  }

  @action
  closeOnEscape(e) {
    if (e.key === 'Escape') {
      this.args.close();
    }
  }

  get labelId() {
    return `modal_${snakeCase(this.args.title)}_label`;
  }
}
