import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixModal @title=\"Résoudre un signalement\" @onCloseButtonClick={{@toggleResolveModal}}>\n  <:content>\n    <PixTextarea\n      @id=\"resolve-reason\"\n      @label=\"Résolution\"\n      type=\"text\"\n      maxLength=\"255\"\n      {{on \"change\" this.onChangeLabel}}\n      {{focus}}\n    />\n  </:content>\n\n  <:footer>\n    <PixButton\n      @type=\"submit\"\n      @size=\"small\"\n      class=\"pix-button--background-transparent-light\"\n      {{on \"click\" @toggleResolveModal}}\n    >\n      Annuler\n    </PixButton>\n    <PixButton @size=\"small\" @triggerAction={{this.resolve}}>Résoudre le signalement</PixButton>\n  </:footer>\n</PixModal>", {"contents":"<PixModal @title=\"Résoudre un signalement\" @onCloseButtonClick={{@toggleResolveModal}}>\n  <:content>\n    <PixTextarea\n      @id=\"resolve-reason\"\n      @label=\"Résolution\"\n      type=\"text\"\n      maxLength=\"255\"\n      {{on \"change\" this.onChangeLabel}}\n      {{focus}}\n    />\n  </:content>\n\n  <:footer>\n    <PixButton\n      @type=\"submit\"\n      @size=\"small\"\n      class=\"pix-button--background-transparent-light\"\n      {{on \"click\" @toggleResolveModal}}\n    >\n      Annuler\n    </PixButton>\n    <PixButton @size=\"small\" @triggerAction={{this.resolve}}>Résoudre le signalement</PixButton>\n  </:footer>\n</PixModal>","moduleName":"pix-admin/components/certifications/issue-reports/resolve-issue-report-modal.hbs","parseOptions":{"srcName":"pix-admin/components/certifications/issue-reports/resolve-issue-report-modal.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CertificationIssueReportModal extends Component {
  @service notifications;

  @tracked label = null;

  @action
  resolve() {
    this.args.resolveIssueReport(this.args.issueReport, this.label);
    this.args.closeResolveModal();
  }

  @action
  onChangeLabel(event) {
    this.label = event.target.value;
  }
}
