import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsModalSimple\n  @title={{this.title}}\n  @closeTitle={{this.closeTitle}}\n  @submitTitle={{this.submitTitle}}\n  @size={{this.size}}\n  @fade={{false}}\n  @open={{@show}}\n  @position=\"center\"\n  @onSubmit={{@confirm}}\n  @onHide={{@cancel}}\n  @submitButtonType={{if @submitButtonType @submitButtonType \"primary\"}}\n>\n  <p>{{@message}}</p>\n  <p class=\"confirm-popup__errors\">{{@error}}</p>\n</BsModalSimple>", {"contents":"<BsModalSimple\n  @title={{this.title}}\n  @closeTitle={{this.closeTitle}}\n  @submitTitle={{this.submitTitle}}\n  @size={{this.size}}\n  @fade={{false}}\n  @open={{@show}}\n  @position=\"center\"\n  @onSubmit={{@confirm}}\n  @onHide={{@cancel}}\n  @submitButtonType={{if @submitButtonType @submitButtonType \"primary\"}}\n>\n  <p>{{@message}}</p>\n  <p class=\"confirm-popup__errors\">{{@error}}</p>\n</BsModalSimple>","moduleName":"pix-admin/components/confirm-popup.hbs","parseOptions":{"srcName":"pix-admin/components/confirm-popup.hbs"}});
import Component from '@glimmer/component';

export default class ConfirmPopup extends Component {
  get title() {
    return this.args.title || 'Merci de confirmer';
  }

  get closeTitle() {
    return this.args.closeTitle || 'Annuler';
  }

  get submitTitle() {
    return this.args.submitTitle || 'Confirmer';
  }

  get size() {
    return this.args.size || null;
  }
}
