define("pix-admin/components/pix-modal-content", ["exports", "@1024pix/pix-ui/components/pix-modal-content"], function (_exports, _pixModalContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pixModalContent.default;
    }
  });
});
